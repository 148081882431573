import React from 'react';
import queryString from 'query-string'
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import { Config } from '../config.js';

const flagUrl = 'https://flagpedia.net/data/flags/small/';
const imgAnon = 'https://www.gravatar.com/avatar/429e504af19fc3e1cfa5c4326ef3394c?s=240&d=mm&r=pg';

class RequireToken extends React.Component {
  constructor(props) {
    super(props);
		console.log(this.props);
  }
	
  componentDidMount() {
		const values = queryString.parse(this.props.location.search)
		const urlToken = Config.apiUrl + '/account/require-token'
		const urlUser = Config.apiUrl + '/account/user'
		
		var dataToken = {
			code: values.code,
			state: values.state
		};

		this.props.toggleLoading();
		
		axios.post(urlToken,dataToken)
      .then(res => {
				console.log('res.data ', res.data);
				this.props.toggleToken(res.data);
				this.props.toggleLoading();
				var dataUser = {
					token: res.data
				};
				var profile = {
					name: null,
					flag: null
				};
				var country;
				
				axios.post(urlUser,dataUser)
					.then(res => {
						//console.log('User INFO ', res.data);
						profile.name = res.data.name;
						country = res.data.country;
						if (country === "none") {
							console.log('country ',country);
							profile.flag = imgAnon;
							console.log('flag URL ', profile.flag);
						} else {
							console.log('country ',country);
							profile.flag = flagUrl+country.toLowerCase()+'.png';
							console.log('flag URL ', profile.flag);
						}
						this.props.setProfile(profile);
					});
				
      });
		
  }
	
  render() {
    return <Redirect to='/' />
  }
}

export default RequireToken;