import React from 'react';
import logo from '../assets/ss-logo.jpg';
import { Link, withRouter } from 'react-router-dom';
import { Container, Row, Col, Navbar, Button } from 'reactstrap';
import { Config } from '../config.js';
import { Spinner } from 'reactstrap';
import '../assets/App.css';


let Url = Config.authUrl + '?response_type=code&client_id=' + Config.clientId +
					'&state=xyz&redirect_uri=' + Config.redirectUri;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
		this.handleClick = this.handleClick.bind(this);
  }
	
	handleClick() {
		if (this.props.tokenValue == null) {
			window.location = Url;
		} else {
			this.props.deleteToken();
			this.props.clearProfile();
			this.props.history.push('/');
		}
	}
	
	render() {
    return (
		<header>
    <Navbar fixed="top" color="light" light expand="xs" className="border-bottom border-gray bg-white" style={{ height: 80 }}>
      <Container>
        <Row noGutters className="position-relative w-100 align-items-center">

          <Col className="d-none d-lg-flex justify-content-start align-items-center">
						<img className="avatarRounded" src={this.props.profile.flag} alt="flag" />
						<span className="mx-1 font-weight-bold"> Welcome </span>
						<span className="text-danger font-weight-bold"> {this.props.profile.name}
						</span>
          </Col>

          <Col className="d-flex justify-content-xs-start justify-content-lg-center">
						<Link to="/">
              <img src={logo} alt="logo" className="position-relative img-fluid" style={{ height: 50 }} />
						</Link>
          </Col>

          <Col className="d-none d-lg-flex justify-content-end align-items-center">
							<Link to="/statistic" className="mr-5 text-secondary font-weight-bold"> Statistics </Link>
							<Button onClick={this.handleClick} color="info" > { (this.props.tokenValue == null) ? 'Login' : 'Logout'} </Button>
							{ (this.props.loading) ? <Spinner color="primary"/> : null }
          </Col>

        </Row>
      </Container>
    </Navbar>
		</header>
		)
	}
}

export default withRouter(Header);