import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import RequireToken from '../components/RequireToken';
import Header from './Header.js';
import Footer from './Footer.js';
import Main from './Main.js';

import TrafficService from './services/TrafficService.js';
import TourismService from './services/TourismService.js';
import TransportService from './services/TransportService.js';
import CulturalService from './services/CulturalService.js';
import ParkingService from './services/ParkingService.js';
import ShopService from './services/ShopService.js';
import Statistic from './Statistic.js';

const imgAnon = 'https://www.gravatar.com/avatar/429e504af19fc3e1cfa5c4326ef3394c?s=240&d=mm&r=pg';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			token: null,
			loading: false,
			userProfile: {
				name: 'Anonymous',
				flag: imgAnon
			}
		};
		console.log('TOKEN ', this.state.token);
		this.toggleToken = this.toggleToken.bind(this);
		this.toggleLoading = this.toggleLoading.bind(this);
		this.deleteToken = this.deleteToken.bind(this);
		this.setProfile = this.setProfile.bind(this);
		this.clearProfile = this.clearProfile.bind(this);
	}

	setProfile(profile) {
		this.setState({userProfile: profile});
	}
	
	clearProfile() {
		var profile = {
			name: 'Anonymous',
			flag: imgAnon
		}
		this.setState({userProfile: profile});
	}
	
	toggleToken(newToken) {
		this.setState({token: newToken});
	}

	deleteToken() {
		this.setState({token: null});
	}
	
	toggleLoading() {
		this.setState(prevState => ({loading: !prevState.loading}));
	}
	
  render() {
    return (
			
			<Router>
			<div>
			
        <Header 
					tokenValue={this.state.token} 
					loading={this.state.loading} 
					deleteToken={this.deleteToken}
					profile={this.state.userProfile}
					clearProfile={this.clearProfile}
				/>
			
				<Switch>
					<Route path='/' render={() => <Main token={this.state.token}/>} exact/>
					<Route 
						path="/oauth/redirect" 
						render={ (props) => <RequireToken toggleToken={this.toggleToken} toggleLoading={this.toggleLoading} 
						setProfile={this.setProfile} {...props}/> } exact
					/>
					<Route path="/service/traffic" render={ () => <TrafficService token={this.state.token} />} exact/>
					<Route path="/service/tourism" render={ () => <TourismService token={this.state.token} />} exact/>
					<Route path="/service/transport" render={ () => <TransportService token={this.state.token} />} exact/>
					<Route path="/service/cultural" render={ () => <CulturalService token={this.state.token} />} exact/>
					<Route path="/service/parking" render={ () => <ParkingService token={this.state.token} />} exact/>
					<Route path="/service/shop" render={ () => <ShopService token={this.state.token} />} exact/>
					<Route path="/statistic" render={ () => <Statistic token={this.state.token} />} exact/>
				</Switch>
								
				<Footer />

			</div>
			</Router>
			
		);
  }
}

export default App;