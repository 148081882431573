import React from 'react';
import { Alert } from 'reactstrap';

const Info = (props) => {
  return (
    <div>
      <Alert color="success">
        <b><h4 className="alert-heading">SmartSantander Services</h4></b>
        <p>
				<a target='_blank' rel="noopener noreferrer" href="http://www.smartsantander.eu/" className="alert-link">SmartSantander</a> provides a unique in the world city-scale experimental research facility in support of typical applications and services for a smart city. 
This friendly web application is a proof of concept of the integration of SmartSantander services by means of the FIWARE Identity Manager–eIDAS authentication gateway, which has been developed in the context of <a target='_blank' rel="noopener noreferrer" href="https://ec.europa.eu/inea/en/connecting-europe-facility/cef-telecom/2017-es-ia-0042" className="alert-link">CEF eID-FIWARE</a> project.
        </p>
        <hr />
        <p className="mb-0">
        In order to use the SmartSantander Services, it is needed to be previously authenticated. Please, use the <b>LOGIN</b> button of the top right.
        </p>
      </Alert>
    </div>
  );
};

export default Info;