import React from 'react';
import { Container, Row, CardDeck } from 'reactstrap';
import Info from './Info.js'
import { withRouter } from 'react-router-dom';
import TrafficCard from './services/TrafficCard.js'
import TourismCard from './services/TourismCard.js'
import TransportCard from './services/TransportCard.js'
import CulturalCard from './services/CulturalCard.js'
import ParkingCard from './services/ParkingCard.js'
import ShopCard from './services/ShopCard.js'

class Main extends React.Component {
	
	render () {

  return (
		<main className="my-5 pb-5">
			<Container className="pt-5 px-0">

				<Row noGutters className="pt-2 pb-2">
					<Info />
					{/*
					<div>
						<span> {this.props.token} </span>
					</div>
					*/}
				</Row>

				<Row noGutters className="pt-2 pb-2">
					<CardDeck>
						<TrafficCard token={this.props.token}/>
						<TourismCard token={this.props.token}/>
						<TransportCard token={this.props.token}/>
					</CardDeck>
				</Row>
				
				<Row noGutters className="pt-2 pb-2">
					<CardDeck>
						<CulturalCard token={this.props.token}/>
						<ParkingCard token={this.props.token}/>
						<ShopCard token={this.props.token}/>
					</CardDeck>
				</Row>
				
			</Container>
		</main>
  );
	}
}

export default withRouter(Main);