import React from 'react';
import { Container, Col, Row, Alert } from 'reactstrap';
import { Config } from '../config.js';
import axios from 'axios';
import { Pie } from '@nivo/pie'

class Statistic extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dataAge: [],
			dataCountry: []
		};
		this.setdataAge = this.setdataAge.bind(this);
		this.setdataCountry = this.setdataCountry.bind(this);
	}
	
	setdataAge(data) {
		this.setState({dataAge: data});
	}
	
	setdataCountry(data) {
		this.setState({dataCountry: data});
	}	
	
  componentDidMount() {
		const urlCountry = Config.apiUrl + '/statistic/country'
		const urlAge = Config.apiUrl + '/statistic/age'
		
		var dataToken = {
			token: this.props.token
		};

		axios.get(urlCountry,dataToken)
      .then(res => {
				console.log('Country INFO ', res.data);
				this.setdataCountry(res.data);
			});
			
		axios.get(urlAge,dataToken)
			.then(res => {
				console.log('Age INFO ', res.data);
				this.setdataAge(res.data);
			});
		
  }
	
	render() {
		const theme = {
			labels: {
				text: { fontSize: 20 }
			}
		};
		
		const margin = {
								top: 40,
								right: 10,
								bottom: 40,
								left: 10
							}
		
    return (
			<main className="my-5 py-5">
				<Container className="px-0">
					<Row noGutters className="pt-2 pb-2">
						<Alert color="success">
							<h4 className="alert-heading">Usage statistics of SmartSantander Services</h4>
							<p>
							These statistics are based on the number of users who have used this application to access SmartSantander services. Since users have to use their eID to access the application, they are only taken into account once for statistics, even if they use this application several times.
							</p>
						</Alert>
					</Row>
					<Row noGutters className="pt-2 pb-2">
					<Col>
						<span className="font-weight-bold"> Age range of the user </span>
						<Pie
						  width={450}
						  height={250}
						  margin={margin}
						  data={this.state.dataAge}
						  animate
						  innerRadius={0.6}
						  padAngle={0.5}
						  cornerRadius={5}
						  radialLabelsLinkColor="inherit"
						  radialLabelsLinkStrokeWidth={3}
						  radialLabelsTextColor="inherit:darker(1.2)"
							colors="set2"
							theme={theme}
						/>
					</Col>
					<Col>
						<span className="font-weight-bold"> Nationality of the user </span>
						<Pie
						  width={450}
						  height={250}
						  margin={margin}
						  data={this.state.dataCountry}
						  animate
						  innerRadius={0.6}
						  padAngle={0.5}
						  cornerRadius={5}
						  radialLabelsLinkColor="inherit"
						  radialLabelsLinkStrokeWidth={3}
						  radialLabelsTextColor="inherit:darker(1.2)"
							colors="set1"
							theme={theme}
						/>
					</Col>
					</Row>
				</Container>
			</main>
    );
	}
}

export default Statistic;