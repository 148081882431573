import React from 'react';
import { Container, Row, Col, Navbar } from 'reactstrap';
import logocef from '../assets/cef-logo.png';

const Footer = () => (
	<footer>
    <Navbar fixed="bottom" color="light" light expand="xs" className="border-bottom border-gray bg-white" style={{ height: 80 }}>
      <Container>
        <Row noGutters className="position-relative w-100 align-items-center">

          <Col className="d-none d-lg-flex justify-content-start">
						<span>2019 © <a target='_blank' rel="noopener noreferrer" href="https://www.tlmat.unican.es/">GIT</a> - Universidad de Cantabria</span>
          </Col>		
          <Col className="d-flex justify-content-end justify-content-lg-center">
              <img src={logocef} alt="logo" className="position-relative img-fluid" style={{ height: 50 }} />
          </Col>
				</Row>
			</Container>
		</Navbar>
	</footer>
);

export default Footer;
