import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { Container, Row, Col } from 'reactstrap';
import {Config} from '../../config.js';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import '../../assets/popup-text.css';

export default class TrafficService extends Component {
	constructor(props) {
		super(props);
		this.state = {
			markers: [],
			lat: 43.470,
			lng: -3.813,
			zoom: 14,
		};
		this.toggleActive = this.toggleActive.bind(this);
	}

  toggleActive() {
    this.setState(prevState => ({active: !prevState.active}));
  }
	
  componentDidMount() {
		console.log('SimpleMap token ', this.props.token);
		
		const url = Config.apiUrl + '/service/traffic'
		var data = {
			token: this.props.token
		};
		
		this.toggleActive();
		window.scrollTo(0, 0);
		
		axios.post(url, data)
      .then(res => {
				console.log('SERVICE res.data ', res.data);
				this.setState({markers: res.data});
				this.toggleActive();
      })
		.catch((error) => {
			if (error.response) {
				console.log('===> Error response data: ',error.response.data);
				console.log('===> Error response status: ',error.response.status);
			} else if (error.request) {
				console.log('===> Error request ',error.request);
			} else {
				console.log('===> Error ', error.message);
			}
		})
  }
	
  render() {
    const position = [this.state.lat, this.state.lng]
		
    const LeafletMarkers = this.state.markers.map(marker => (
      <Marker position={marker.location} key={marker.id}>
        <Popup>
					<b>Traffic data</b><br /> <br />
          Road Load: <span>{marker.roadLoad}</span><br /> 
					Road Occupancy: <span>{marker.roadOccupancy}</span><br /> 
					Traffic Intensity: <span>{marker.trafficIntensity}</span><br /> 
        </Popup>
      </Marker>
    ));
		
    return (
			<main className="my-5 py-5">
				<Container className="px-0">
				
				<Row>
				<Col xs="auto">
				<p className="font-weight-bold text-primary h4">Traffic Service</p>
				</Col>
				<Col>
				{ (this.state.active) ? <Spinner color="primary"/> : null }
				</Col>
				</Row>
				
				<Row>
				<Map center={position} zoom={this.state.zoom}>
					<TileLayer
						attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
						url="https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}"
						id = 'mapbox.streets'
						accessToken = 'pk.eyJ1IjoiamNob3F1ZSIsImEiOiJjamtqaGk5ZXQxYnB4M3FxaGppeHZwZDMxIn0.NwXwlfVZAJl61hkSTUnr3Q'
					/>
					{LeafletMarkers}
				</Map>
				</Row>
				
				</Container>
			</main>
    )
  }
}