import React from 'react';
import { Card, Button, CardImg, CardTitle, CardText, CardBody } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';

class TransportCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
			isMouseInside: false,
    };
		// This binding is necessary to make `this` work in the callback
		this.handleClick = this.handleClick.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		
    this.mouseEnter = this.mouseEnter.bind(this);
		this.mouseLeave = this.mouseLeave.bind(this);
	}

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }
	
  handleClick() {
    if (this.props.token != null) {
			this.props.history.push('/service/transport');
		} 
		else {
			this.toggleModal();
		}
  }
	
	mouseEnter = () => {
		this.setState({ isMouseInside: true });
	}
	mouseLeave = () => {
		this.setState({ isMouseInside: false });
	}
	
	render () {
	const imgTraffic = require('../../assets/transport.jpg');
	return (

		<Card onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
			<CardImg top width="100%" src={imgTraffic} alt="Transport" />
			<CardBody>
				<CardTitle><b>Transport Service</b></CardTitle>
				<CardText>Check the bus stops of Santander's transport network. It shows the geolocation of the bus stop as well as the direction in which the bus is traveling. </CardText>

					{this.state.isMouseInside ? 
						<Button color="success" onClick={this.handleClick}> Show it </Button> : null
					}
				
					<Modal isOpen={this.state.modal} toggle={this.toggleModal} >
						<ModalHeader toggle={this.toggleModal}>Need to login in</ModalHeader>
						<ModalBody>
							You need to be authenticated in order to use SmartSantander Services. Please use the <b>Login</b> button located on the upper right corner.
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={this.toggleModal}>Close</Button>
						</ModalFooter>
					</Modal>
			</CardBody>
		</Card>

	);
	}
}

export default withRouter(TransportCard);