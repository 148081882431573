import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { Container, Row, Col } from 'reactstrap';
import { Config } from '../../config.js';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import '../../assets/popup-image.css';

export default class CulturalService extends Component {
	constructor(props) {
		super(props);
		this.state = {
			markers: [],
			lat: 43.475735, 
			lng: -3.803587,
			zoom: 14,
		};
		this.toggleActive = this.toggleActive.bind(this);
	}

  toggleActive() {
    this.setState(prevState => ({active: !prevState.active}));
  }
	
  componentDidMount() {
		const url = Config.apiUrl + '/service/cultural'
		var data = {
			token: this.props.token
		};

		this.toggleActive();
		window.scrollTo(0, 0);
		
		axios.post(url, data)
      .then(res => {
				console.log('SERVICE Cultural res.data ', res.data);
				this.setState({markers: res.data});
				this.toggleActive();
      })
		.catch((error) => {
			if (error.response) {
				console.log('===> Error response data: ',error.response.data);
				console.log('===> Error response status: ',error.response.status);
			} else if (error.request) {
				console.log('===> Error request ',error.request);
			} else {
				console.log('===> Error ', error.message);
			}
		})
  }
	
  render() {
    const position = [this.state.lat, this.state.lng]
		
    const LeafletMarkers = this.state.markers.map(marker => (
      <Marker position={marker.location} key={marker.id}>
        <Popup>
					<div>
					<img src={marker.imageUrl} alt="Cultural"  />
					<b>{marker.category}</b><br /> <br />
					Date: <span>{marker.date}</span><br />
					Description: <span>{marker.description}</span><br />
					</div>
        </Popup>
      </Marker>
    ));
		
    return (
			<main className="my-5 py-5">
				<Container className="px-0">
				
				<Row>
				<Col xs="auto">
				<p className="font-weight-bold text-primary h4">Cultural Events Service</p>
				</Col>
				<Col>
				{ (this.state.active) ? <Spinner color="primary"/> : null }
				</Col>
				</Row>
				
				<Row>
				<Map center={position} zoom={this.state.zoom}>
					<TileLayer
						attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
						url="https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}"
						id = 'mapbox.streets'
						accessToken = 'pk.eyJ1IjoiamNob3F1ZSIsImEiOiJjamtqaGk5ZXQxYnB4M3FxaGppeHZwZDMxIn0.NwXwlfVZAJl61hkSTUnr3Q'
					/>
					{LeafletMarkers}
				</Map>
				</Row>
				
				</Container>
			</main>
    )
  }
}